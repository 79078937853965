import store from '../state';
var {getData} = require('./api.js');
var mediaConstraints = {
    audio: true,
};
var mediaRecorder;
export function start() {
    navigator.mediaDevices.getUserMedia({audio: true}).then(stream => {
        mediaRecorder = new MediaRecorder(stream);
        mediaRecorder.start();
        const audioChunks = [];
        mediaRecorder.addEventListener('dataavailable', event => {
            audioChunks.push(event.data);
        });
        mediaRecorder.onstop = function(e) {
            const audioBlob = new Blob(audioChunks);
            const audioUrl = URL.createObjectURL(audioBlob);
            store.dispatch({
                type: 'AudioBlob',
                blob: audioUrl,
            });
            tobase64(audioBlob);
        };
    });
}
export function stop() {
    mediaRecorder.stop();
}

export function tobase64(audioBlob) {
    let reader = new window.FileReader();
    reader.readAsDataURL(audioBlob);
    reader.onloadend = function() {
        var audioBase64 = reader.result;
        let audioTurned = audioBase64.substr(audioBase64.indexOf(',') + 1);
        getData(store.getState().text, audioTurned, false);
    };
}

import React, {Component} from 'react';
import InputType from './InputType.js';
import ResultComponent from './ResultComponent.js';
import ExamplesComponent from './ExamplesComponent.js';
import FaqComponent from './FaqComponent.js';
import RecommendComponent from './RecommendComponent.js';

import Grid from '@material-ui/core/Grid';

class Speech extends Component {
    render() {
        return (
            <Grid container>
                <Grid item xs={12} className="faq">
                    <div className="container">
                        <FaqComponent />
                    </div>
                </Grid>

                <Grid item xs={6}>
                    <div className="split">
                        <h1 className="try">Try Yourself</h1>
                        <InputType />
                        <h1 className="recommend">
                            Practice Word/Sentence Recommendation
                        </h1>
                        <RecommendComponent />
                        <h1 className="examples-title">Try Other Samples</h1>
                        <ExamplesComponent />
                    </div>
                </Grid>
                <Grid item xs={6} className="result">
                    <div className="split">
                        <ResultComponent />
                    </div>
                </Grid>
            </Grid>
        );
    }
}

export default Speech;

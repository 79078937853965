import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import FileCopy from '@material-ui/icons/FileCopy';
class TitlePageComponent extends Component {
    constructor(props) {
        super(props);
        let serverSelected = 'global';
        const servers = ['global', 'cn', 'us', 'v1-dev'];

        if (window.location.hash) {
            let server = window.location.hash.split('#')[1];
            if (servers.indexOf(server) > -1) {
                serverSelected = server;
            }
        }
        window.location.hash = serverSelected;

        this.state = {
            serverSelected,
            servers,
        };
    }

    selectionChange(e) {
        this.setState({serverSelected: e.target.value});
        window.location.hash = e.target.value;
    }

    render() {
        return (
            <div className="header">
                <Grid container>
                    <Grid item xs={2}>
                        <div className="img-block">
                            <svg
                                id="ef-logo"
                                viewBox="0 0 82.02 47.84"
                                width="70px"
                            >
                                <title>logo</title>
                                <path d="M9.68.07L8 8.41A55.82 55.82 0 0 1 14.72.07zM12.07 17.52A33.14 33.14 0 0 0 9.08 31a26.43 26.43 0 0 0 1.8 9.73 24.32 24.32 0 0 0 4.34 7.12h8.25a18.84 18.84 0 0 1-3.19-2.6A22.28 22.28 0 0 1 14 27.16 29.22 29.22 0 0 1 17 17a37.28 37.28 0 0 1 7-9.68A35.12 35.12 0 0 1 33.43.44l.77-.37h-8a44.6 44.6 0 0 0-5.17 4.49 43.6 43.6 0 0 0-9 13z" />
                                <path d="M22.62 11.17h4l.65-.67a27 27 0 0 1 15.08-7.92l.51-2.51h-4.4a33.06 33.06 0 0 0-13.35 8.32q-1.34 1.35-2.5 2.78zM47.75 31.08c2.8-2.83 4.34-6.09 4.34-9.18a8.7 8.7 0 0 0-2.57-6.23 7.86 7.86 0 0 0-2.82-1.83l-.54 2.67a6.32 6.32 0 0 1 1.44 1.09 7.24 7.24 0 0 1 2 5.77 10 10 0 0 1-3 6.16A11.74 11.74 0 0 1 43 32l-.51 2.5a14.69 14.69 0 0 0 5.23-3.45z" />
                                <path d="M53.66 21.9c0 3.51-1.7 7.17-4.8 10.3a16.09 16.09 0 0 1-6.7 4.13l-.6 2.93a19.94 19.94 0 0 0 8.85-5.34c6.81-6.88 7.84-16.22 2.4-21.73a11 11 0 0 0-5.17-2.94l-.62 3a9.43 9.43 0 0 1 3.61 2.26 10.25 10.25 0 0 1 3 7.35z" />
                                <path d="M48.06 23.25a5.68 5.68 0 0 0-1.56-4.53 4.84 4.84 0 0 0-.67-.56l-2.41 11.9a10.14 10.14 0 0 0 2.13-1.65 8.41 8.41 0 0 0 2.51-5.17zM2.72 44.33a31.43 31.43 0 0 1-1.24-3.82L0 47.84h4.37a30.79 30.79 0 0 1-1.65-3.51zM7.51 31a34.75 34.75 0 0 1 3.13-14.12A45.25 45.25 0 0 1 20 3.44 46.37 46.37 0 0 1 23.66.07h-6.72l-.8.8A52.66 52.66 0 0 0 7 13.08L2.3 36.49a30.69 30.69 0 0 0 1.88 7.26 29.57 29.57 0 0 0 2 4.1h7a25.91 25.91 0 0 1-3.76-6.55A28 28 0 0 1 7.51 31zM20.69 28.58v.68h3.54a15.71 15.71 0 0 1-.09-1.65A19 19 0 0 1 26.92 18h-3.34a21.91 21.91 0 0 0-2.89 10.59zM36.53 18h-2.88A13.39 13.39 0 0 0 30 26.75a9.52 9.52 0 0 0 .33 2.51h2.78a7.32 7.32 0 0 1-.77-3.26 10.35 10.35 0 0 1 3.23-7.18 11.71 11.71 0 0 1 1-.87zM25.72 27.61a14.56 14.56 0 0 0 .1 1.65h2.87a11.16 11.16 0 0 1-.28-2.51 13.28 13.28 0 0 1 1.17-5.36 16.17 16.17 0 0 1 2-3.4h-2.81a17.67 17.67 0 0 0-3.05 9.62z" />
                                <path d="M51.52 35a21.23 21.23 0 0 1-10.31 6l-.64 3.16a25.93 25.93 0 0 0 13.11-7.21 26.29 26.29 0 0 0 7.6-14.41 19.17 19.17 0 0 0 .2-2.71v-.63a15.87 15.87 0 0 0-4.72-10.85 14.89 14.89 0 0 0-8.1-4.16L48 7.7a12.63 12.63 0 0 1 6 3.37c6.07 6.14 5 16.45-2.4 24z" />
                                <path d="M57.86 7.23a17.62 17.62 0 0 1 4.42 7.34l.07.24.74-3.64h2.09a20 20 0 0 0-4.46-6.82A18.27 18.27 0 0 0 54.07.07H49.5L49 2.64a18 18 0 0 1 2.18.53 16.14 16.14 0 0 1 6.71 4.06zM66.85 11.17h5.32A25 25 0 0 0 66.26.07h-8.53a19.39 19.39 0 0 1 4.1 3.16 21.54 21.54 0 0 1 5 7.94zM41.35 7.55L42 4.24a25.55 25.55 0 0 0-13.16 6.93h4.27a20.16 20.16 0 0 1 8.22-3.62zM41 9.23a18.22 18.22 0 0 0-5 1.94h4.59L41 9.23zM15.6 27.31a20.73 20.73 0 0 0 5.77 16.82 17.65 17.65 0 0 0 5.4 3.71h6.38l.4-2a16.73 16.73 0 0 1-12.69-9.38h-3.71l1.43-7.25h.55v-.68A23.21 23.21 0 0 1 21.8 18h-.92l1.25-6.21a30.18 30.18 0 0 0-6.53 15.52z" />
                                <path d="M35.48 36.52h-6.07a11.93 11.93 0 0 0 5.47 2.95l.6-2.93zM34.56 41a13.48 13.48 0 0 1-6.62-3.73c-.25-.25-.48-.5-.7-.77h-4.61a15.13 15.13 0 0 0 11.25 7.82l.68-3.32zM55.66 47.84l1.07-5.27A33.8 33.8 0 0 1 49 47.84zM66.56 19.8H63a20.37 20.37 0 0 1-.21 2.94A25.09 25.09 0 0 1 60 31.07h3.51a28.74 28.74 0 0 0 2.9-9.38c.08-.63.13-1.26.15-1.89zM75.72.07h-7.37a26.64 26.64 0 0 1 5.44 11.1h5.7a31.78 31.78 0 0 0-1.86-7.11 30.26 30.26 0 0 0-1.91-4zM77.52.07a32.46 32.46 0 0 1 2.93 7.79L82 .07zM72.87 19.8h-4.75c0 .69-.08 1.38-.17 2.08a30.07 30.07 0 0 1-2.71 9.19h4.83a35 35 0 0 0 2.71-10.18c0-.36.07-.72.09-1.09zM74.33 21.05a36.41 36.41 0 0 1-2.57 10h3.61l2.25-11.25h-3.18c0 .42-.06.84-.1 1.25zM40.23 45.79l-.41 2h5.26a32 32 0 0 0 11.72-7.47l.49-.51 1.3-6.42a31.64 31.64 0 0 1-3.8 4.61 27.36 27.36 0 0 1-14.56 7.76zM36.68 20a8.77 8.77 0 0 0-2.77 6 5.5 5.5 0 0 0 1 3.22h1.67l2.17-10.88a9.94 9.94 0 0 0-2 1.6z" />
                            </svg>
                        </div>
                    </Grid>
                    <Grid item xs={7}>
                        <div className="nav-item">
                            <NavLink
                                to={`/${window.location.hash}`}
                                isActive={(match, location) =>
                                    match && match.isExact
                                }
                            >
                                Speech Scoring
                            </NavLink>
                            <NavLink
                                to={`/intonation${window.location.hash}`}
                                isActive={(match, location) =>
                                    match && match.isExact
                                }
                            >
                                Intonation Trainer
                            </NavLink>
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <Button
                            variant="outlined"
                            color="primary"
                            href="https://app.swaggerhub.com/apis-docs/eflsd/speech-scoring"
                            target="_blank"
                            className="faq-button"
                        >
                            <FileCopy />
                            Docs
                        </Button>

                        <FormControl>
                            <Select
                                value={this.state.serverSelected}
                                onChange={e => this.selectionChange(e)}
                            >
                                {this.state.servers.map((el, index) => (
                                    <MenuItem value={el} key={index}>
                                        {el}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>Server</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default TitlePageComponent;

import React, {Component} from 'react';
import WordsComponent from './WordsComponent.js';
import PhonemeComponent from './PhonemeComponent.js';
import NavigationIcon from '@material-ui/icons/Navigation';
import CircularProgress from '@material-ui/core/CircularProgress';

import store from '../state.js';

class ResultComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: null,
        };
    }
    componentDidMount() {
        this.unsubscribe = store.subscribe(() => {
            let state = store.getState();
            if (state.recommend) {
                this.setState({
                    recommend: state.recommend,
                    data: null,
                });
            } else if (state.data) {
                this.setState({
                    data: state.data,
                    audioBlob: state.blob,
                    recommend: null,
                    mode: state.mode,
                });
            }
            this.setState({loading: !!state.loading});
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    render() {
        return (
            <div>
                <div
                    className={
                        !this.state.data || this.state.loading ? 'hide' : ''
                    }
                >
                    <div>
                        <h3>Audio for mode: {this.state.mode}</h3>
                        <audio controls src={this.state.audioBlob} />
                    </div>
                    {this.state.mode == 'keyword' ? null : (
                        <div className="inline-components">
                            <WordsComponent />
                            <h3>Phoneme (experimental)</h3>
                            <PhonemeComponent />
                        </div>
                    )}

                    <h3>Raw Output</h3>
                    <div className="json">
                        <pre>{JSON.stringify(this.state.data, null, 2)}</pre>
                    </div>
                </div>

                <div
                    className={
                        !this.state.recommend || this.state.loading
                            ? 'hide'
                            : ''
                    }
                >
                    <h3>Recommended words</h3>
                    <div>
                        <ul>
                            {this.state.recommend &&
                                this.state.recommend.words.map(
                                    (word, index) => <li>{word.word}</li>
                                )}
                        </ul>
                    </div>
                    <h3>Recommended sentences</h3>
                    <div>
                        <ul>
                            {this.state.recommend &&
                                this.state.recommend.sentences.map(
                                    (sent, index) => <li>{sent.origin_sent}</li>
                                )}
                        </ul>
                    </div>
                    <h3>
                        Key pronunciation problems used for recommendation (with
                        weights)
                    </h3>
                    <div>
                        <ul>
                            {this.state.recommend &&
                                this.state.recommend.input_stat.g2p_candidates.map(
                                    (g2p, index) => (
                                        <li className={index > 4 ? 'hide' : ''}>
                                            {g2p[0]} ({g2p[1]})
                                        </li>
                                    )
                                )}
                        </ul>
                    </div>
                    <h3>Raw Output</h3>
                    <div className="json">
                        <pre>
                            {JSON.stringify(this.state.recommend, null, 2)}
                        </pre>
                    </div>
                </div>

                <div
                    className={
                        ((!this.state.data && !this.state.recommend) ||
                        this.state.loading
                            ? ''
                            : 'hide') + ' result-empty'
                    }
                >
                    <div className={this.state.loading ? '' : 'hide'}>
                        <CircularProgress />
                    </div>
                    <div className={this.state.loading ? 'hide' : ''}>
                        <NavigationIcon className="icon" />
                    </div>
                    <div className="text">
                        {this.state.loading
                            ? 'Calling API'
                            : 'Score will show here after you try the API'}
                    </div>
                </div>
            </div>
        );
    }
}

export default ResultComponent;

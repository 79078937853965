import React, {Component} from 'react';
import NavigationIcon from '@material-ui/icons/Navigation';
import CircularProgress from '@material-ui/core/CircularProgress';

import store from '../state.js';

class ResultComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: null,
        };

        this.chart = null;
    }

    componentDidMount() {
        this.unsubscribe = store.subscribe(() => {
            let state = store.getState();
            if (state.text) {
                // resize
                this.setState({intonationInnerWidth: state.text.length * 30});
            }

            if (state.intonation && !state.loading) {
                const plotData = state.intonation;

                const labels = Array.from(
                    Array(plotData.ref_plot.length).keys()
                );

                var config = {
                    type: 'line',
                    data: {
                        labels,
                        datasets: [
                            {
                                label: 'Reference',
                                data: plotData.ref_plot,
                                borderColor: 'rgb(255, 99, 132)',
                                backgroundColor: 'rgb(255, 99, 132)',
                                fill: true,
                            },
                            {
                                label: 'Actual (student)',
                                data: plotData.act_plot,
                                borderColor: 'rgb(54, 162, 235)',
                                fill: false,
                            },
                        ],
                    },
                    options: {
                        responsive: true,
                        maintainAspectRatio: false,
                        title: {
                            display: false,
                        },
                        tooltips: {
                            enabled: false,
                        },
                        elements: {
                            point: {
                                radius: 0,
                            },
                        },
                        scales: {
                            yAxes: [
                                {
                                    ticks: {
                                        display: false,
                                    },
                                    gridLines: {
                                        display: false,
                                    },
                                },
                            ],
                            xAxes: [
                                {
                                    ticks: {
                                        display: false,
                                    },
                                    gridLines: {
                                        display: false,
                                    },
                                },
                            ],
                        },
                    },
                    plugins: [
                        {
                            afterRender: function(chart, options) {
                                var ctx = chart.ctx;
                                // console.log(chart, options)

                                var widthPerFrame =
                                    chart.width / plotData.ref_plot.length;

                                ctx.fillStyle = '#000000';
                                ctx.strokeStyle = '#000000';
                                ctx.font = '12px Arial';
                                ctx.setLineDash([20, 30]);
                                ctx.lineWidth = 2;
                                for (var word of plotData.word_info) {
                                    var wPosition = parseInt(
                                        ((word['start_frame'] +
                                            word['end_frame']) *
                                            widthPerFrame) /
                                            2 -
                                            (word['word'].length / 2) * 10
                                    );
                                    var lPosition =
                                        (word['end_frame'] + 1) * widthPerFrame;
                                    ctx.fillText(
                                        word['word'],
                                        wPosition,
                                        chart.height - 30
                                    );

                                    // draw line
                                    ctx.beginPath();
                                    ctx.moveTo(lPosition, 100);
                                    ctx.lineTo(lPosition, chart.height - 20);
                                    ctx.stroke();
                                }
                            },
                        },
                    ],
                };

                setTimeout(() => {
                    const canvas = document.getElementById('canvas');
                    const ctx = canvas.getContext('2d');
                    if (this.chart) {
                        this.chart.destroy();
                        // ctx.clearRect(0, 0, canvas.width, canvas.height);
                    }
                    this.chart = new window.Chart(ctx, config);
                    console.log('render');
                }, 100);
            }

            this.setState({
                loading: !!state.loading,
                intonation: state.intonation,
            });
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    render() {
        return (
            <div>
                <div
                    className={
                        !this.state.intonation || this.state.loading
                            ? 'hide'
                            : ''
                    }
                >
                    <h1>Intonation Graph (scroll horizontally if necessary)</h1>
                    <div className="intonation-outer">
                        <div
                            className="intonation-inner"
                            style={{width: this.state.intonationInnerWidth}}
                        >
                            <canvas id="canvas" />
                        </div>
                    </div>
                </div>

                <div
                    className={
                        (!this.state.intonation || this.state.loading
                            ? ''
                            : 'hide') + ' result-empty'
                    }
                >
                    <div className={this.state.loading ? '' : 'hide'}>
                        <CircularProgress />
                    </div>
                    <div className={this.state.loading ? 'hide' : ''}>
                        <NavigationIcon className="icon" />
                    </div>
                    <div className="text">
                        {this.state.loading
                            ? 'Calling API'
                            : 'Score will show here after you try the API'}
                    </div>
                </div>
            </div>
        );
    }
}

export default ResultComponent;

import React, {Component} from 'react';
import store from '../state';
class WordsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            score: null,
        };
    }

    componentDidMount() {
        this.setState({data: null});
        this.unsubscribe = store.subscribe(() => {
            const state = store.getState();
            if (state.data && state.mode != 'keyword') {
                this.setState({
                    profile: state.data.profile_name,
                    data: state.data.words,
                    pronScore: state.data.utterance_score.toFixed(2),
                    fluencyScore: state.data.utterance_fluency_score.toFixed(2),
                    stressScore:
                        state.data.utterance_stress_score !== null
                            ? state.data.utterance_stress_score.toFixed(2)
                            : null,
                    timeConsumed: state.timeConsumed.toFixed(1),
                });
            }
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    wordClass(word) {
        if (word.word_state == 9) return 'strike';
        if (word.word_category == 3) return 'green';
        if (word.word_category == 2) return 'orange';
        if (word.word_category == 1) return 'red';
        return 'gray';
    }

    render() {
        if (this.state.data) {
            return (
                <div>
                    <div>
                        <h3>
                            Pronunciation Accuracy: {this.state.pronScore},{' '}
                            {this.state.timeConsumed} seconds, by profile:{' '}
                            {this.state.profile}
                        </h3>
                        <h3>Fluency: {this.state.fluencyScore}</h3>
                        <h3>Stress: {this.state.stressScore}</h3>
                        <h3>Word breakdown: accuracy score (stress)</h3>
                    </div>
                    <div className="words-block">
                        {this.state.data.map((word, index) => (
                            <div
                                className={this.wordClass(word) + ' word'}
                                key={index}
                            >
                                <div className="text">
                                    <span>{word.word}</span>
                                </div>
                                <div className="score">
                                    <span>{word.word_score.toFixed(2)}</span>
                                    <span className="stress">
                                        {word.word_stress_hit == null
                                            ? ''
                                            : `${
                                                  word.word_stress_hit > 0
                                                      ? word.word_stress_hit ==
                                                        1
                                                          ? 'hit'
                                                          : 'partial'
                                                      : 'miss'
                                              }`}
                                    </span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            );
        }
        return <div />;
    }
}

export default WordsComponent;

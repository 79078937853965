import React, {Component} from 'react';
import Speech from './Components/Speech/Speech';
import Intonation from './Components/Intonation/Intonation';

import TitlePageComponent from './Components/TitlePageComponent';

import Divider from '@material-ui/core/Divider';

import './css/App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';

class App extends Component {
    render() {
        return (
            <Router basename={'/speech'}>
                <div>
                    <div className="container">
                        <TitlePageComponent />
                    </div>

                    <Divider />

                    <Switch>
                        <Route exact path="/" component={Speech} />
                        <Route
                            exact
                            path="/intonation"
                            component={Intonation}
                        />
                    </Switch>

                    <div className="footer">&copy; Signum International AG</div>
                </div>
            </Router>
        );
    }
}

export default App;

import React, {Component} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import originalExamples from '../samples';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

import NavigationIcon from '@material-ui/icons/Navigation';
import store from '../state';
var {getData} = require('../api/api.js');

class ExamplesComponent extends Component {
    constructor(props) {
        super(props);

        let categories = [];
        for (var i = 0; i < originalExamples.length; i++) {
            if (categories.indexOf(originalExamples[i].category) === -1) {
                categories.push(originalExamples[i].category);
            }
        }

        this.state = {
            selected: '',
            categories,
            examples: originalExamples,
        };

        window.addEventListener(
            'hashchange',
            () => {
                this.handleExamples();
            },
            false
        );
    }

    componentDidMount() {
        this.handleExamples();
    }

    handleExamples() {
        const cn = 'cn-north-1.amazonaws.com.cn/cn.ef.speech-api';
        const us = 'amazonaws.com/com.ef.speechscoringdata-us';
        const isCn = window.location.hash.indexOf('cn') > 0;
        const examples = [];

        for (let oex of originalExamples) {
            const ex = Object.assign({}, oex);
            if (isCn) {
                ex.audio = oex.audio.replace(us, cn);
            }
            examples.push(ex);
        }

        this.setState({examples});
    }

    selectionChange(e) {
        this.setState({selected: e.target.value});
    }

    startScoring(input, audio) {
        getData(input, audio);
        store.dispatch({
            type: 'AudioBlob',
            blob: audio,
        });
    }
    render() {
        return (
            <div>
                <div className="category-select">
                    <FormControl>
                        <Select
                            value={this.state.selected}
                            onChange={e => this.selectionChange(e)}
                            displayEmpty
                        >
                            <MenuItem value="">All</MenuItem>
                            {this.state.categories.map((el, index) => (
                                <MenuItem value={el} key={index}>
                                    {el}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <Paper className="example-paper">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Text</TableCell>
                                <TableCell>Audio</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.examples.map((row, index) => {
                                if (
                                    row.category === this.state.selected ||
                                    this.state.selected === ''
                                ) {
                                    return (
                                        <TableRow key={index}>
                                            <TableCell
                                                component="th"
                                                scope="row"
                                            >
                                                {row.input}
                                            </TableCell>
                                            <TableCell>
                                                <audio
                                                    controls
                                                    src={row.audio}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                <Button
                                                    size="small"
                                                    onClick={e =>
                                                        this.startScoring(
                                                            row.input,
                                                            row.audio
                                                        )
                                                    }
                                                    className="input-button"
                                                >
                                                    <NavigationIcon />
                                                    Score
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            })}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
        );
    }
}

export default ExamplesComponent;

import {createStore} from 'redux';

function reducer(state = {}, action) {
    switch (action.type) {
        case 'text':
            return {...state, ...{text: action.text, intonation: null}};
        case 'AudioBlob':
            return {...state, ...{blob: action.blob}};
        case 'data':
            return {...state, ...action};
        case 'recommend':
            return {...state, ...action};
        case 'loading':
            return {...state, ...{loading: action.loading}};
        case 'profile':
            return {...state, ...{profile: action.profile}};
        case 'lang':
            return {...state, ...{lang: action.lang}};
        case 'mode':
            return {
                ...state,
                ...{mode: action.mode, data: null, recommed: null},
            };
        case 'speaker_id':
            return {...state, ...{speaker_id: action.speaker_id}};
        case 'intonationRef':
            return {
                ...state,
                ...{ref: action.ref, intonation: null, data: null, lang: null},
            };
        case 'intonation':
            return {...state, ...action};
        default:
            return state;
    }
}
const store = createStore(reducer, {
    text: '',
    profile: 'default',
    loading: false,
});
export default store;

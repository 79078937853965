import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import store from './Components/state';
import App from './App';
import registerServiceWorker from './registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
// store.subscribe(() => console.log('New State!',store.getState()));
registerServiceWorker();

import React, {Component} from 'react';

class FAQ extends Component {
    render() {
        return (
            <div>
                <div>
                    <h1>Speech Scoring and Recommendation API</h1>
                    <span className="subtitle">
                        Cloud API to give sentence-, word- and phone-level
                        pronunciation scores, and generate word and sentence
                        recommendations for practice
                    </span>
                </div>
                <br />

                <div className="questions">
                    <h2>What is Speech Scoring?</h2>
                    This Speech Scoring engine is trained with thousands of
                    hours of native (American) English data and it will return
                    how well the input speaks against American English. It gives
                    scores of the entire sentence, words and the probability of
                    the phoneme inside the words and the possible alternatives.
                    Highlights:
                    <ul>
                        <li>Best internal score across market competitors</li>
                        <li>Customizable models to suit different groups</li>
                        <li>
                            We store all data (yes, including audio), you can
                            fetch it via API
                        </li>
                        <li>Global deployment</li>
                    </ul>
                    <h2>What does Recommendation API do?</h2>
                    Since we persist all results from our Speech Scoring engine,
                    and we understand deeply how to interpret the scores, our
                    Recommendation Engine fetches the history of a particular
                    speaker, build statistical models and search the best match
                    from our content pool. Highlights:
                    <ul>
                        <li>
                            All-in-one solution, you don't need to put different
                            pieces from vendors any more
                        </li>
                        <li>
                            Customizable content pools, you can upload your own
                        </li>
                        <li>
                            Based on the errors you made in your historical
                            speech practice
                        </li>
                        <li>Global deployment</li>
                    </ul>
                </div>
            </div>
        );
    }
}

export default FAQ;

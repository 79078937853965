import React, {Component} from 'react';
import store from '../state';
class PhonemeComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.unsubscribe = store.subscribe(() => {
            const state = store.getState();
            if (state.data && state.mode != 'keyword') {
                this.setState({
                    data: store.getState().data,
                    audioBlob: store.getState().blob,
                });
            }
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    letterChecker(g2p, phones) {
        let score = 0;
        let count = 0;
        for (let g of g2p.phonemes) {
            for (let p of phones) {
                if (p.phone_index == g.phone_index) {
                    score += p.phone_score;
                    count += 1;
                }
            }
        }
        const avg = count ? score / count : 1;
        if (avg < 0.3) {
            return 'gray';
        }

        return 'green';
    }
    render() {
        if (this.state.data) {
            return (
                <div className="phoneme-container">
                    <div className="phoneme-block inline">
                        {this.state.data.words.map((word, index) => (
                            <div className="phoneme-word inline" key={index}>
                                {word.g2p_info.map((g2p, index) => (
                                    <div
                                        className="phoneme-letter inline"
                                        key={'phoneme' + index}
                                    >
                                        <span
                                            className={this.letterChecker(
                                                g2p,
                                                word.phones
                                            )}
                                        >
                                            {g2p.letters}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        ))}
                    </div>
                </div>
            );
        }

        return <div />;
    }
}

export default PhonemeComponent;

import React, {Component} from 'react';

class FAQ extends Component {
    render() {
        return (
            <div>
                <div>
                    <h1>Speech Intonation Trainer</h1>
                    <span className="subtitle">
                        With the result from the Speech Scoring API, you can
                        gather further intonation features and compare between a
                        reference and the student input
                    </span>
                </div>
                <br />

                <div className="questions">
                    <h2>What is Intonation?</h2>
                    Intonation is the melody of speech, the pitch of the voice,
                    the stress of the sound. It delivers rich meanings in
                    addition to the content of the language.
                    <h2>How to use the API?</h2>
                    You use the speech scoring API to get the result of the
                    reference (and make sure to save it), then you get the same
                    API output from the student input. After that, combine both
                    results, unchanged, to feed to the intonation API and you
                    will get the graph data and word/phone level information.
                    You can render them according to your applications.
                </div>
            </div>
        );
    }
}

export default FAQ;

import axios from 'axios';
import store from '../state';

const envs = {
    local: 'v1-dev',
    cn: 'v1',
    us: 'v1',
    global: 'v1',
    hk: 'v1',
};

const urls = {
    local: 'http://localhost:8080',
    'v1-dev': 'https://speech-us.ai.ef.com',
    cn: 'https://speech-cn.ai.ef.cn',
    us: 'https://speech-us.ai.ef.com',
    hk: 'https://speech-hk.ai.ef.com',
    global: 'https://speech.ai.ef.com',
};

const tokens = {
    default:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwYXJ0bmVyX2lkIjoidGVzdCIsInR5cGUiOiJhY2Nlc3MiLCJpYXQiOjE1NDY1MTM2MTYsImp0aSI6ImNhZGFkODMxLTZhM2MtNDc5YS05ZTQyLThkNmZlNTVlNTg2NyIsInNjb3BlIjoic2NvcmU6Z2V0In0.y4gahlDjwxhS6IjVbfxI9cdruCcNk9FnMAlXuumBjaQ',
    cn:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwYXJ0bmVyX2lkIjoidGVzdCIsInR5cGUiOiJhY2Nlc3MiLCJpYXQiOjE1NDgzMTU0NDYsImp0aSI6IjlkMzU3MGM0LTcwYjAtNDRmZi1iNzY5LWY4NjZhMDFhNjU1YSIsInNjb3BlIjoic2NvcmU6Z2V0In0.jfn6Xwq1xeohIb6TpzcBP5mnrTtcepE2UST15E2_LB0',
    us:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwYXJ0bmVyX2lkIjoidGVzdCIsInR5cGUiOiJhY2Nlc3MiLCJpYXQiOjE1NDgxMDU5MDAsImp0aSI6IjI5NmFjOWY0LTg3YWItNGM4Yi05ZGNjLWZkOGYwN2MwNjcyNCIsInNjb3BlIjoic2NvcmU6Z2V0In0.DN331K_V-ypvIo6obevoeU9HTTP72BiEIzefjRaV7iI',
    global:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwYXJ0bmVyX2lkIjoidGVzdCIsInR5cGUiOiJhY2Nlc3MiLCJpYXQiOjE1NDgxMDU5MDAsImp0aSI6IjI5NmFjOWY0LTg3YWItNGM4Yi05ZGNjLWZkOGYwN2MwNjcyNCIsInNjb3BlIjoic2NvcmU6Z2V0In0.DN331K_V-ypvIo6obevoeU9HTTP72BiEIzefjRaV7iI',
    hk:
        'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJwYXJ0bmVyX2lkIjoidGVzdCIsInR5cGUiOiJhY2Nlc3MiLCJpYXQiOjE1NDgxMDU5MDAsImp0aSI6IjI5NmFjOWY0LTg3YWItNGM4Yi05ZGNjLWZkOGYwN2MwNjcyNCIsInNjb3BlIjoic2NvcmU6Z2V0In0.DN331K_V-ypvIo6obevoeU9HTTP72BiEIzefjRaV7iI',
};

function getAuth() {
    let apiKey = 'l9gQk3hVTx2ad4CbU5XcY3pyq4EmIQDl1f32MlTY';
    const envKey = window.location.hash
        ? window.location.hash.split('#')[1]
        : 'global';
    const env = envs[envKey] || envKey;
    const baseUrl = urls[envKey] || urls['global'];
    const url = `${baseUrl}/${env}/`;
    const token = tokens[envKey] || tokens['default'];

    return [url, apiKey, token];
}

export function getData(input, audio) {
    console.log(store.getState());
    const profile = store.getState().profile;
    const lang = store.getState().lang || 'en';
    const mode = store.getState().mode;
    const speaker_id = store.getState().speaker_id;

    const [url, apiKey, token] = getAuth();
    let requestUrl = '';

    if (mode == 'keyword') {
        requestUrl = url + 'score/keyword';
    } else {
        requestUrl = url + 'score/' + lang;
    }

    console.log(requestUrl);

    store.dispatch({
        type: 'loading',
        loading: true,
    });

    const tic = Date.now();
    axios
        .post(
            requestUrl,
            {
                input,
                audio,
                profile_name: profile,
                speaker_id,
                trim_noise: 'strong',
                original_word_mapping: true,
                intonation_graph: true,
            },
            {
                headers: {
                    'x-api-key': apiKey,
                    Authorization: 'Bearer ' + token,
                },
            }
        )
        .then(function(response) {
            if (response.data.error) {
                throw new Error(response.data.error);
            }

            store.dispatch({
                type: 'data',
                data: response.data,
                recommend: null,
                timeConsumed: (Date.now() - tic) / 1000,
                mode,
            });
            store.dispatch({
                type: 'loading',
                loading: false,
            });
        })
        .catch(function(error) {
            alert(error);
            store.dispatch({
                type: 'loading',
                loading: false,
            });
        });
}

export function getRecommendation() {
    const speaker_id = store.getState().speaker_id;
    const [url, apiKey, token] = getAuth();
    store.dispatch({
        type: 'loading',
        loading: true,
    });

    const tic = Date.now();
    axios
        .post(
            url + 'recommend',
            {
                speaker_id,
            },
            {
                headers: {
                    'x-api-key': apiKey,
                    Authorization: 'Bearer ' + token,
                },
            }
        )
        .then(function(response) {
            if (response.data.error) {
                throw new Error(response.data.error);
            }

            store.dispatch({
                type: 'recommend',
                recommend: response.data,
                data: null, // clear data
                timeConsumed: (Date.now() - tic) / 1000,
            });

            store.dispatch({
                type: 'loading',
                loading: false,
            });
        })
        .catch(function(error) {
            alert(error);
            store.dispatch({
                type: 'loading',
                loading: false,
            });
        });
}

export function getIntonationGraph(refAudioUrl, actJSON) {
    const [url, apiKey, token] = getAuth();

    store.dispatch({
        type: 'loading',
        loading: true,
    });

    store.dispatch({
        type: 'data',
        data: null, // clear data
    });

    const tic = Date.now();
    axios
        .post(
            url + 'score',
            {
                input: actJSON.original_transcription,
                audio: refAudioUrl,
                profile_name: actJSON.profile,
                speaker_id: actJSON.speaker_id,
                original_word_mapping: true,
                intonation_graph: true,
            },
            {
                headers: {
                    'x-api-key': apiKey,
                    Authorization: 'Bearer ' + token,
                },
            }
        )
        .then(response => {
            const ref = response.data;
            return axios.post(
                url + 'score/intonation',
                {
                    ref,
                    act: actJSON,
                },
                {
                    headers: {
                        'x-api-key': apiKey,
                        Authorization: 'Bearer ' + token,
                    },
                }
            );
        })
        .then(function(response) {
            if (response.data.error) {
                throw new Error(response.data.error);
            }

            store.dispatch({
                type: 'intonation',
                intonation: response.data,
                recommend: null,
                data: null, // clear data
                timeConsumed: (Date.now() - tic) / 1000,
            });

            store.dispatch({
                type: 'loading',
                loading: false,
            });
        })
        .catch(function(error) {
            alert(`${error}: ${error.response}`);
            store.dispatch({
                type: 'loading',
                loading: false,
            });
        });
}

export default [
    {
        script: 'To infinity and beyond!',
        source: 'Toy Story',
        ref_audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/intonation/toystory_ref.mp3',
        ref_url:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/intonation/toystory_ref.json',
    },
    {
        script: "I really like the shirt but I don't think I'm gonna get it!",
        source: 'Youtube',
        ref_audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/intonation/shirt_ref.mp3',
        ref_url:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/intonation/shirt_ref.json',
    },
    {
        script: 'It says I have to have a lesson to learn how to park.',
        source: 'Peppa Pig',
        ref_audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/intonation/peppa_ref.mp3',
        ref_url:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/intonation/peppa_ref.json',
    },
    {
        script:
            "You wanna to learn the first rule? You'd know if you spent a day in your life. You never open your mouth until you know what the shot is. ",
        source: 'Glengarry Glen Ross',
        ref_audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/intonation/glengarryglenross_ref.mp3',
        ref_url:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/intonation/glengarryglenross_ref.json',
    },
    {
        script:
            'Yesterday is history, tomorrow is a mystery, but today is a gift. That is why it is called the "present".',
        source: 'Kung Fu Panda',
        ref_audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/intonation/kungfupanda_ref.mp3',
        ref_url:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/intonation/kungfupanda_ref.json',
    },
];

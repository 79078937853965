import React, {Component} from 'react';
import store from '../state';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

const {getRecommendation} = require('../api/api.js');

class RecommendComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
        };
    }

    textChange(e) {
        store.dispatch({
            type: 'speaker_id',
            speaker_id: e.target.value,
        });
    }

    onRecClick(e) {
        getRecommendation();
    }

    componentDidMount() {
        this.unsubscribe = store.subscribe(() => {
            if (store.getState().speaker_id == '') {
                this.setState({
                    disabled: true,
                });
            } else {
                this.setState({
                    disabled: false,
                });
            }
            this.setState({speaker_id: store.getState().speaker_id});
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    render() {
        return (
            <div className="input-type">
                <TextField
                    id="text-el"
                    label="speaker_id"
                    margin="normal"
                    onChange={event => this.textChange(event)}
                    value={this.state.speaker_id}
                    required
                />
                <Button
                    variant="contained"
                    onClick={e => this.onRecClick(e)}
                    disabled={this.state.disabled}
                    className="input-button"
                >
                    Recommend
                </Button>
            </div>
        );
    }
}
export default RecommendComponent;

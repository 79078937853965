import React, {Component} from 'react';
import store from '../state';
import KeyboardVoice from '@material-ui/icons/KeyboardVoice';
import Button from '@material-ui/core/Button';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Link from '@material-ui/icons/Link';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';

import samples from '../intonationSamples.js';

const {tobase64, start, stop} = require('../api/voice.js');
const {getData, getIntonationGraph} = require('../api/api.js');

const refSentences = samples.map(s => s.script);

class InputType extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selected: 'default',
            refSelected: refSentences[0],
            refAudio: samples[0].ref_audio,
            refUrl: samples[0].ref_url,
            source: samples[0].source,
            profiles: ['default', 'kids_cn'],
            ref: refSentences,
        };
    }

    componentDidMount() {
        store.dispatch({
            type: 'text',
            text: refSentences[0],
        });

        store.dispatch({
            type: 'intonationRef',
            ref: samples[0],
        });

        this.unsubscribe = store.subscribe(() => {
            let state = store.getState();

            this.setState({audioBlob: state.blob});
            if (state.data && !state.loading) {
                const ref = state.ref;
                getIntonationGraph(ref.ref_audio, state.data);
            }
        });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    onClickMicro(e) {
        if (!this.state.micro) {
            start();
            this.setState({micro: !this.state.micro});
        } else {
            stop();
            this.setState({micro: !this.state.micro});
        }
    }

    onClickLink(e) {
        let url = prompt('Please enter the audio URL');

        if (url != null && url.indexOf('http') == 0) {
            store.dispatch({
                type: 'AudioBlob',
                blob: url,
            });
            getData(store.getState().text, url);
        } else {
            alert('URL is invalid');
        }
    }

    fileChange(e) {
        var path = (window.URL || window.webkitURL).createObjectURL(
            e.target.files[0]
        );
        store.dispatch({
            type: 'AudioBlob',
            blob: path,
        });

        tobase64(e.target.files[0]);

        // remove file selectionChange
        e.target.value = '';
    }

    selectionChange(e) {
        this.setState({selected: e.target.value});
        store.dispatch({
            type: 'profile',
            profile: e.target.value,
        });
    }

    refSelectionChange(e) {
        store.dispatch({
            type: 'text',
            text: e.target.value,
        });

        this.setState({refSelected: e.target.value});

        const idx = refSentences.indexOf(e.target.value);
        this.setState({
            refAudio: samples[idx].ref_audio,
            source: samples[idx].source,
        });

        store.dispatch({
            type: 'intonationRef',
            ref: samples[idx],
        });
    }

    render() {
        return (
            <div className="input-type intonation-select">
                <FormControl fullWidth margin="normal">
                    <Select
                        fullWidth
                        value={this.state.refSelected}
                        onChange={e => this.refSelectionChange(e)}
                    >
                        {this.state.ref.map((el, index) => (
                            <MenuItem value={el} key={index}>
                                {el}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl>
                    <div>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={this.state.disabled}
                            onClick={e => this.onClickMicro(e)}
                            className="input-button"
                        >
                            <KeyboardVoice />
                            {!this.state.micro ? 'Record' : 'Stop'}
                        </Button>

                        <input
                            accept="audio/*"
                            id="contained-button-file"
                            type="file"
                            onChange={e => this.fileChange(e)}
                            className="hide"
                        />
                        <label htmlFor="contained-button-file">
                            <Button
                                variant="outlined"
                                component="span"
                                className="input-button"
                            >
                                <CloudUpload />
                                Upload
                            </Button>
                        </label>

                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={e => this.onClickLink(e)}
                            className="input-button"
                        >
                            <Link />
                            URL
                        </Button>

                        <FormControl>
                            <Select
                                value={this.state.selected}
                                onChange={e => this.selectionChange(e)}
                            >
                                {this.state.profiles.map((el, index) => (
                                    <MenuItem value={el} key={index}>
                                        {el}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>Scoring profile</FormHelperText>
                        </FormControl>
                    </div>
                </FormControl>
                <div>
                    <div>
                        <h3>Text</h3>
                        <p>{this.state.refSelected}</p>
                    </div>
                    <div>
                        <h3>Reference Audio</h3>
                        <p>Source: {this.state.source}</p>
                        <audio controls src={this.state.refAudio} />
                    </div>

                    <div>
                        <h3>Input Audio</h3>
                        <audio controls src={this.state.audioBlob} />
                    </div>
                </div>
            </div>
        );
    }
}
export default InputType;

export default [
    {
        category: 'kid',
        input: 'How are you',
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/kids/howareyou.m4a',
    },
    {
        category: 'kid',
        input: 'How are you',
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/kids/howareyou1.m4a',
    },
    {
        category: 'kid',
        input: 'How are you',
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/kids/howareyou2.m4a',
    },
    {
        category: 'kid',
        input: "I'm happy",
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/kids/iamhappy.m4a',
    },
    {
        category: 'kid',
        input: "It's green",
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/kids/itsgreen.m4a',
    },
    {
        category: 'kid',
        input: "It's green",
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/kids/itsgreen1.m4a',
    },
    {
        category: 'kid',
        input: "It's green",
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/kids/itsgreenbad.m4a',
    },
    {
        category: 'kid',
        input: 'I will catch the horse',
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/kids/iwillcatchthehorse.wav',
    },
    {
        category: 'Adult',
        input: 'Can you tell me everything from the very beginning',
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/adult/canyoutellmeeverythingfromthebeginning.wav',
    },
    {
        category: 'Adult',
        input: "I'm not sure about that",
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/adult/i+am+not+sure+about+that.wav',
    },
    {
        category: 'Adult',
        input: "I'm sure you would agree",
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/adult/i+am+sure+you+would+agree.wav',
    },
    {
        category: 'Adult',
        input: 'Oh ok, do you like the apartment?',
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/adult/ohokdoyouliketheapartment.wav',
    },
    {
        category: 'Adult',
        input: 'Thanks for your listening',
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/adult/thank+for+your+listening.wav',
    },
    {
        category: 'Adult',
        input: 'They live at 8 3 0 0 9 Golf Road',
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/adult/theyliveat3009golfroad.wav',
    },
    {
        category: 'Adult',
        input: 'wind',
        audio:
            'https://s3.amazonaws.com/com.ef.speechscoringdata-us/static/adult/wind.wav',
    },
];

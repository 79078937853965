import React, {Component} from 'react';
import IntonationInput from './IntonationInput';
import IntonationResult from './IntonationResult';
import FaqComponent from './IntonationFaq';

import Grid from '@material-ui/core/Grid';

class Intonation extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        const script = document.createElement('script');
        script.src =
            'https://cdn.jsdelivr.net/npm/chart.js@2.9.3/dist/Chart.min.js';
        script.async = true;

        document.body.appendChild(script);
    }

    render() {
        return (
            <Grid container>
                <Grid item xs={12} className="faq">
                    <div className="container">
                        <FaqComponent />
                    </div>
                </Grid>
                <Grid item xs={4}>
                    <div className="split">
                        <h1 className="try">Try Yourself</h1>
                        <IntonationInput />
                    </div>
                </Grid>
                <Grid item xs={8}>
                    <div className="split">
                        <IntonationResult />
                    </div>
                </Grid>
            </Grid>
        );
    }
}
export default Intonation;

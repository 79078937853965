import React, {Component} from 'react';
import store from '../state';
import KeyboardVoice from '@material-ui/icons/KeyboardVoice';
import Button from '@material-ui/core/Button';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Link from '@material-ui/icons/Link';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';

const {tobase64, start, stop} = require('../api/voice.js');
const {getData} = require('../api/api.js');

class InputType extends Component {
    constructor(props) {
        super(props);
        const self = this;

        this.state = {
            disabled: true,
            selected: 'default',
            lang: 'en',
            mode: 'speech',
            profiles: [
                'default',
                'kids_cn',
                'assessment',
                'efset',
                'tf_default',
                'tf_phonics',
            ],
            langs: [
                'en',
                'de',
                'fr',
                'es',
                'ja',
                'pt',
                'it',
                'sv',
                'da',
                'fi',
                'no',
                'ru',
            ],
            modes: ['speech', 'keyword'],
            showMode: true,
        };

        window.addEventListener(
            'hashchange',
            () => {
                this.handleShowLang();
            },
            false
        );
    }
    textChange(e) {
        store.dispatch({
            type: 'text',
            text: e.target.value,
        });
    }

    speakerTextChange(e) {
        store.dispatch({
            type: 'speaker_id',
            speaker_id: e.target.value,
        });
    }

    handleShowLang() {
        let env = window.location.hash
            ? window.location.hash.split('#')[1]
            : '';
        this.setState({showLang: env != 'cn'});
    }

    componentDidMount() {
        this.unsubscribe = store.subscribe(() => {
            if (store.getState().text == '') {
                this.setState({
                    disabled: true,
                });
            } else {
                this.setState({
                    disabled: false,
                });
            }
            this.setState({speaker_id: store.getState().speaker_id});
        });

        this.handleShowLang();
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    onClickMicro(e) {
        if (!this.state.micro) {
            start();
            this.setState({micro: !this.state.micro});
        } else {
            stop();
            this.setState({micro: !this.state.micro});
        }
    }

    onClickLink(e) {
        let url = prompt('Please enter the audio URL');

        if (url != null && url.indexOf('http') == 0) {
            store.dispatch({
                type: 'AudioBlob',
                blob: url,
            });
            getData(store.getState().text, url);
        } else {
            alert('URL is invalid');
        }
    }

    fileChange(e) {
        var path = (window.URL || window.webkitURL).createObjectURL(
            e.target.files[0]
        );
        store.dispatch({
            type: 'AudioBlob',
            blob: path,
        });

        tobase64(e.target.files[0]);

        // remove file selectionChange
        e.target.value = '';
    }

    selectionChange(e) {
        this.setState({selected: e.target.value});
        store.dispatch({
            type: 'profile',
            profile: e.target.value,
        });
    }

    langChange(e) {
        this.setState({lang: e.target.value});
        store.dispatch({
            type: 'lang',
            lang: e.target.value,
        });
    }

    modeChange(e) {
        this.setState({mode: e.target.value});
        store.dispatch({
            type: 'mode',
            mode: e.target.value,
        });
    }

    render() {
        return (
            <div className="input-type">
                <FormControl fullWidth>
                    <Grid container spacing={8}>
                        <Grid item sm={8}>
                            <TextField
                                id="text-el"
                                label="Input script here"
                                margin="normal"
                                onChange={event => this.textChange(event)}
                                required
                                fullWidth
                            />
                        </Grid>
                        <Grid item sm={4}>
                            <TextField
                                id="text-el-speaker"
                                label="speaker_id"
                                margin="normal"
                                onChange={event =>
                                    this.speakerTextChange(event)
                                }
                                value={this.state.speaker_id}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
                <FormControl>
                    <div>
                        <Button
                            variant="contained"
                            color="secondary"
                            disabled={this.state.disabled}
                            onClick={e => this.onClickMicro(e)}
                            className="input-button"
                        >
                            <KeyboardVoice />
                            {!this.state.micro ? 'Record' : 'Stop'}
                        </Button>

                        <input
                            accept="audio/*"
                            id="contained-button-file"
                            type="file"
                            onChange={e => this.fileChange(e)}
                            className="hide"
                        />
                        <label htmlFor="contained-button-file">
                            <Button
                                variant="outlined"
                                component="span"
                                disabled={this.state.disabled}
                                className="input-button"
                            >
                                <CloudUpload />
                                Upload
                            </Button>
                        </label>

                        <Button
                            variant="outlined"
                            color="secondary"
                            disabled={this.state.disabled}
                            onClick={e => this.onClickLink(e)}
                            className="input-button"
                        >
                            <Link />
                            URL
                        </Button>

                        <FormControl>
                            <Select
                                value={this.state.selected}
                                onChange={e => this.selectionChange(e)}
                            >
                                {this.state.profiles.map((el, index) => (
                                    <MenuItem value={el} key={index}>
                                        {el}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText>Scoring profile</FormHelperText>
                        </FormControl>
                        {this.state.showLang ? (
                            <FormControl style={{marginLeft: '30px'}}>
                                <Select
                                    value={this.state.lang}
                                    onChange={e => this.langChange(e)}
                                >
                                    {this.state.langs.map((el, index) => (
                                        <MenuItem value={el} key={index}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>Language</FormHelperText>
                            </FormControl>
                        ) : (
                            ''
                        )}
                        {this.state.showMode ? (
                            <FormControl style={{marginLeft: '30px'}}>
                                <Select
                                    value={this.state.mode}
                                    onChange={e => this.modeChange(e)}
                                >
                                    {this.state.modes.map((el, index) => (
                                        <MenuItem value={el} key={index}>
                                            {el}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText>Mode</FormHelperText>
                            </FormControl>
                        ) : (
                            ''
                        )}
                    </div>
                </FormControl>
            </div>
        );
    }
}
export default InputType;
